<template>
  <div class="base-empty">
    <BaseImg
      class="base-empty__icon"
      :src="iconEmpty"
      alt="icon empty"
    />
    <div
      v-if="emptyData?.text"
      class="base-empty__text"
    >
      {{ emptyData?.text }}
    </div>
    <p
      v-if="emptyData?.subText"
      class="base-empty__subText"
    >
      {{ emptyData?.subText }}
    </p>
    <slot name="content" />
  </div>
</template>

<script setup lang="ts">
import { PATH_IMAGES } from '~/config/constant'
import { IEmptyData } from '~/types/common'
const props = defineProps<{
  emptyData?: IEmptyData;
}>()

const iconEmpty = computed(() => {
  return props.emptyData?.icon || `${PATH_IMAGES}icon/icon-empty.svg`
})
</script>
<style lang="scss" scoped src="public/assets/scss/components/common/base-empty.scss" />
